<template>
  <div class="page" id="reorganize">
    <el-row :gutter="10">
      <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
        <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
             @click="showList = !showList"></div>
        <div :class="['bg-white','leftHeighe']" v-show="showList">
          <el-descriptions title="文物类别"></el-descriptions>
          <div class="flex_b_c">
            <el-input size="small" placeholder="输入关键字进行过滤" clearable v-model="filterText"
                      @input="filterTreeData"></el-input>
          </div>
          <el-tree v-loading="treeLoading" node-key="id" ref="tree" :data="treeData" class="treeDateCls"
                   show-checkbox :filter-node-method="filterNode"
                   :props="defaultProps" @check="selRecord">
            <div slot-scope="{ node, data }">
              <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                <div class="omit">{{ node.label }}</div>
              </el-tooltip>
            </div>
          </el-tree>
        </div>
      </el-col>
      <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
        <div class="text_center noData" v-if="!archivesBasicDataId.length">请选择左侧文物类别</div>
        <div v-else>
          <div class="bg-white">
            <el-form class="query-form dynamicForm tabsCls2" size="small" ref="inputForm"
                     @keyup.enter.native="getDataList(1,1)"
                     :model="inputForm"
                     :rules="rules" label-width="150px">
              <el-form-item label="文物名称" prop="name">
                <el-input v-model.trim="inputForm.name"
                          placeholder="请输入文物名称(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
              <el-form-item label="现状情况" prop="heritageAppraisal">
                <el-select v-model="inputForm.heritageAppraisal" clearable
                           placeholder="请选择现状情况" style="width: 100%;">
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('assessment_status')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="年份（年）" prop="year">
                <el-date-picker
                    v-model="inputForm.year"
                    clearable
                    type="year"
                    value-format="yyyy"
                    style="width: 100%;"
                    placeholder="选择年份">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="完残程度" prop="integrity">
                <el-select v-model="inputForm.integrity"
                           placeholder="请选择完残程度"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in integrityList"
                      :key="item.id"
                      :label="item.levelName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="完残情况" prop="integrityInfo">
                <el-input v-model="inputForm.integrityInfo"
                          placeholder="请输入完残情况(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
            </el-form>
            <div class="flex_b_c">
              <div>
                <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">
                  查询
                </el-button>
                <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置
                </el-button>
              </div>
              <div>
                <el-button type="primary"
                           size="small"
                           v-if="selectTab == 0 || selectTab == 99"
                           v-show="hasPermissionButton(`collection:immovable:${typePage}:add`)"
                           icon='el-icon-plus'
                           @click="addData(0)">新增
                </el-button>
              </div>
            </div>
          </div>

          <div class="bg-white" style="margin-top: 10px">
            <div class="flex_b_c">
              <div class="tabsCls" v-if="typePage != 'manage'">
                <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
                  <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                               :name="item.value"></el-tab-pane>
                </el-tabs>
              </div>
              <div></div>
              <div>
                <el-button size="small"
                           v-show="hasPermissionButton(`collection:immovable:${typePage}:batchExpor`)"
                           @click="exportData(1)">
                  <i class="icon-piliangdaochu iconfont buIcon"/>
                  批量导出
                </el-button>
                <el-button icon="el-icon-upload2" size="small" v-if="selectTab == 0 || selectTab == 99"
                           v-show="hasPermissionButton(`collection:immovable:${typePage}:import`)"
                           @click="importDile()">
                  批量导入
                </el-button>
                <el-button icon="el-icon-position" size="small" v-if="selectTab == 0"
                           v-show="hasPermissionButton(`collection:immovable:${typePage}:batchSubmit`)"
                           @click="submitTo(1)">
                  批量提交
                </el-button>
                <el-button icon="el-icon-download" size="small" v-if="selectTab == 0 || selectTab == 99"
                           v-show="hasPermissionButton(`collection:immovable:${typePage}:template`)"
                           @click="templateDownload()">
                  导入模板下载
                </el-button>
              </div>
            </div>
            <el-table
                :data="dataList"
                size="small"
                v-loading="loading"
                ref="multipleTable"
                height="calc(100vh - 350px)"
                class="table"
                :row-key="'id'"
                :header-cell-style="{background:'#F9DFDF'}"
                @selection-change="selectionChangeHandle"
            >
              <el-table-column :reserve-selection="true" type="selection" width="50"/>
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="processState" label="审核状态" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ $dictUtils.getDictLabel("process_state", scope.row.processState, '-') }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="图标" width="120px">
                <template slot-scope="scope">
                  <div class="flex_a_c">
                    <div v-if="scope.row.carded == 0"
                         class="icon-wenwukapian iconfont listIcon"></div>
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="scope.row.picMasterViewUrl"
                        :fit="'cover'"
                        :preview-src-list="[scope.row.picMasterViewUrl]"
                    >
                      <div slot="error" class="image-slot">
                        <el-image
                            style="width: 36px; height: 36px"
                            :src="require('@/assets/img/default.png')"
                            :fit="'cover'">
                        </el-image>
                      </div>
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="文物名称"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="culturalRelicsCategory" label="文物类别"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="culturalRelicsType" label="文物分类" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.culturalRelicsType ? scope.row.culturalRelicsType : scope.row.other }}
                </template>
              </el-table-column>

              <el-table-column prop="quantity" label="数量" show-overflow-tooltip></el-table-column>
              <el-table-column prop="year" label="年份" show-overflow-tooltip></el-table-column>
              <el-table-column prop="integrityInfo" label="完残情况"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
              <el-table-column prop="heritageAppraisal" label="现状评估" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ $dictUtils.getDictLabel("assessment_status", scope.row.heritageAppraisal, '-') }}
                </template>
              </el-table-column>
              <el-table-column prop="createName" label="创建者" show-overflow-tooltip></el-table-column>
              <el-table-column prop="createDate" sortable label="创建时间" width="150px">
                <template slot-scope="scope">
                  {{ scope.row.createDate | formatDate }}
                </template>
              </el-table-column>
              <el-table-column prop="updateName" label="最后更新人"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.updateDate | formatDate }}
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="180">
                <template slot-scope="scope">
                  <el-button size="mini" type="text"
                             @click="addData(1, scope.row,scope.$index)"
                             v-show="hasPermissionButton(`collection:immovable:${typePage}:detail`)">
                    详情
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="submitTo(2,scope.row)"
                             v-if="scope.row.processState == 0"
                             v-show="hasPermissionButton(`collection:immovable:${typePage}:submit`)">
                    提交
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="submitTo(2,scope.row)"
                             v-if="scope.row.processState == 2"
                             v-show="hasPermissionButton(`collection:immovable:${typePage}:resubmit`)">
                    重新提交
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="addData(2, scope.row,scope.$index)"
                             v-if="(scope.row.processState !=  1 && scope.row.processState !=  3) || typePage == 'manage'"
                             v-show="hasPermissionButton(`collection:immovable:${typePage}:edit`)">修改
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="record(scope.row)"
                             v-show="hasPermissionButton(`collection:immovable:${typePage}:record`)">
                    审核记录
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="deleteDate(scope.row)"
                             v-if="scope.row.processState == 0 || scope.row.processState == 2 || typePage == 'manage'"
                             v-show="hasPermissionButton(`collection:immovable:${typePage}:delete`)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="text_center">
              <el-pagination
                  @size-change="sizeChangeHandle"
                  @current-change="currentChangeHandle"
                  :current-page.sync="pageNo"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  :total="total"
                  background
                  layout="total, sizes, prev, pager, next, jumper"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <record ref="record"></record>
    <ImportColl ref="importColl" @importFile="getDataList(1,1)"></ImportColl>
  </div>
</template>

<script>
import {hasPermissionButton} from "@/utils";
import record from "@/views/modules/collection/accounts/module/record.vue";
import ImportColl from "@/views/modules/collection/immovable/importColl.vue";


export default {
  name: "immovableReg",
  components: {record, ImportColl},
  props: {
    typePage: {
      type: String,
      default: 'immovableReg',
    }
  },
  data() {
    return {
      treeData: [],
      treeLoading: false,
      filterText: '',
      defaultProps: {
        children: 'childList',
        label: 'name'
      },
      showList: true,

      selectTab: '99',
      tabsList: [
        {
          name: '全部',
          value: '99',
        },
        {
          name: '审核中',
          value: '1',
        },
        {
          name: '审核驳回',
          value: '2',
        },
        {
          name: '草稿',
          value: '0',
        },
      ],
      inputForm: {
        name: '',
        heritageAppraisal: '',
        year: '',
        integrity: '',
        integrityInfo: '',
      },
      rules: {},
      integrityList: [
        {
          id: '残缺',
          levelName: '残缺',
        },
        {
          id: '基本完整',
          levelName: '基本完整',
        },
        {
          id: '完整',
          levelName: '完整',
        },
        {
          id: '严重残缺',
          levelName: '严重残缺',
        },
      ],
      archivesBasicDataId: [], //左侧选中数据
      loading: false,
      dataList: [],

      dataListSelect: [],
      pageNo: 1,
      pageSize: 10,
      pageNo2: 0,
      total: 0,

      searchRecord: {},
    }
  },

  mounted() {
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      this.selectTab = listSearch.selectTab
      this.archivesBasicDataId = listSearch.archivesBasicDataId
      this.inputForm = listSearch.inputForm
      this.pageSize = listSearch.pageSize
      this.typePage = listSearch.typePage
      this.pageNo2 = listSearch.pageNo
      this.searchTree(1)
    } else {
      this.searchTree()
    }
  },

  methods: {
    hasPermissionButton,

    // 查询树状数据
    searchTree(type) {
      this.treeData = [
        {
          id: '',
          name: '全部',
          childList: [],
        },
      ]
      this.treeLoading = true
      this.$axios(this.api.collection.collectionImmovableCulturalRelicTypeList, {}, 'get').then((res) => {
        if (res.status) {
          this.treeData[0].childList = res.data
          //全选
          this.$nextTick(() => {
            if (!type) {
              this.getTreeId(this.treeData)

            }
            this.$refs.tree.setCheckedKeys(this.archivesBasicDataId);
            this.selRecord('', '')
          })
        }
        this.treeLoading = false
      })
    }
    ,

    //循环取树形控件id
    getTreeId(data) {
      if (data && data.length != 0) {
        data.forEach(item => {
          this.archivesBasicDataId.push(item.id)
          if (item.childList) {
            this.getTreeId(item.childList)
          }
        })
      } else {
        return
      }
    },

    // 选择档案分类
    selRecord(data, checked) {
      //该节点所对应的对象、树目前的选中状态对象
      if (checked) {
        this.archivesBasicDataId = []
        this.archivesBasicDataId = checked.checkedNodes.map(item => {
          return item.id
        })
      }
      if (this.archivesBasicDataId.length == 0) return
      this.getDataList(1, 1)
    },

    //获取列表数据
    getDataList(type, dividePage) {
      if (type == 1) {
        this.pageNo = 1
      }
      if (dividePage == 1) {
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
      }
      this.loading = true
      let archivesBasicDataId, processState
      archivesBasicDataId = this.archivesBasicDataId.filter(item => {
        return item != ''
      })
      if (this.selectTab == 99) {
        processState = [0, 1, 2]
      } else {
        processState = [this.selectTab]
      }
      if (this.typePage == 'manage') {
        processState = [3]
      }
      this.searchRecord = {
        processState: processState,
        ...this.inputForm,
        current: this.pageNo2 ? this.pageNo2 : this.pageNo,
        size: this.pageSize,
        culturalRelicsCategory: archivesBasicDataId,
      }
      this.$axios(this.api.collection.collectionImmovableCulturalRelicList, this.searchRecord, 'post').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
          this.total = parseInt(res.data.total)
          let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
          if (listSearch) {
            this.pageNo = listSearch.pageNo
            this.pageNo2 = 0
            sessionStorage.removeItem('listSearch')
          }
          if (this.dataList.length == 0 && this.pageNo > 1) {
            this.pageNo--
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },


    //导入
    importDile() {
      this.$refs.importColl.init(this.treeData[0].childList, this.api.collection.importExcel, '导入不可移动文物')
    },

    //筛选树的数据
    filterTreeData() {
      this.$refs.tree.filter(this.filterText);
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    //重置
    resetting() {
      this.$refs.inputForm.resetFields();
      this.getDataList(1, 1)
    },

    //导出 type 1批量导出 2全部导出
    exportData(type) {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = this.dataListSelect.map(item => {
        if (item) {
          return item.id
        }
      })
      this.exportExcel(this.api.collection.collectionImmovableCulturalRelicExport, {ids}, '不可移动文物', 'get')
    },

    //提交 type 1批量提交 2单个提交
    submitTo(type, row) {
      let ids
      if (type == 1) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        ids = this.dataListSelect.map(item => {
          if (item) {
            return item.id
          }
        })
      } else {
        ids = [row.id]
      }
      this.$confirm(`您是否确认提交数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.relicBacthSubmit, {
          ids: ids,
          revdId: 10,
        }, 'post').then(data => {
          if (data && data.status) {
            this.$message.success('提交成功')
            this.getDataList('', 1)
          }
        })
      })
    }
    ,

    //导入模板下载
    templateDownload() {
      this.exportExcel(this.api.collection.templateDownload, {}, '不可移动文物模板', 'get')
    },

    // 0新增 1详情 2修改 4重新提交
    addData(num, row, index) {
      if (num != 0) {
        let listSearch = {
          selectTab: this.selectTab,
          archivesBasicDataId: this.archivesBasicDataId,
          inputForm: this.inputForm,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          typePage: this.typePage,
        }
        sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
        this.$set(this.searchRecord, 'viewCurrent', (this.pageNo - 1) * this.pageSize + index + 1)
        sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
      }
      if (num == 0) {
        this.$router.push({
          path: '/collection/immovable/addMovable',
          query: {butType: num},
        })
      } else {
        this.$router.push({
          path: '/collection/immovable/addMovable',
          query: {butType: num, id: row.id, typePage: this.typePage}
        })
      }
    },

    //审核记录
    record(row) {
      this.$refs.record.init(row.id, '', 10)
    },

    //删除数据
    deleteDate(row) {
      this.$confirm(`您是否确认删除数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.relicRemove + row.id, {}, 'delete').then(data => {
          if (data && data.status) {
            this.$message.success('删除成功')
            this.getDataList('', 1)
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    }
    ,

    handleClick(tab, event) {
      this.getDataList(1, 1)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList('', 2);
    },
  },
}
</script>

<style lang="scss" scoped>
.leftHeighe {
  height: calc(100vh - 124px);
  overflow-y: auto;
}

.treeDateCls {
  overflow-y: auto !important;
  height: 88%;
}

.magTop {
  margin-top: 10px;
}

.tabsCls2 {
  margin: 15px 0 0;
}

/*去掉tabs底部的下划线*/
.tabsCls > > > .el-tabs__nav-wrap::after {
  position: static !important;
}

.buIcon {
  font-size: 12px;
  margin-right: 5px;
}


.noData {
  line-height: calc(100vh - 154px);
}

.table {
  &::v-deep .el-table {
    .el-table__fixed {
      height: auto !important;
      bottom: 10px !important;
    }
  }

  &::v-deep .el-table__body-wrapper {
    z-index: 2
  }
}

.table {
  .el-table__fixed-right,
  .el-table__fixed {
    height: auto !important;
    bottom: 17px !important;
  }
}
</style>